import Styled from 'styled-components'

const Estilo = Styled.div`
    margin: 0;
    padding: 0;

    .clientes{
        list-style: none;
    }

`

export default Estilo